<template>
  <div class="User_root">
    <div class="User__container">
      <div class="User_change-password">
        <PasswordChange :form="form" @updateField="handleUpdateFields" @submitChange="handleChangePassword" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import inputRules from '@/utils/inputRules';
import PasswordChange from './PasswordChange/PasswordChange';

export default {
  components: {
    PasswordChange,
  },
  data: () => ({
    inputRules,
    form: {
      confirm_password: '',
      old_password: '',
      new_password: '',
    },
  }),
  methods: {
    ...mapActions('user', ['apiChangePasswordAction']),
    handleUpdateFields({ value, field }) {
      this.form[field] = value;
    },
    clearChangePasswordForm() {
      this.form = {
        confirm_password: '',
        old_password: '',
        new_password: '',
      };
    },
    handleChangePassword() {
      const { old_password, new_password } = this.form;
      this.apiChangePasswordAction({
        old_password,
        new_password,
      }).then(() => this.clearChangePasswordForm());
    },
  },
};
</script>

<style lang="scss" scoped>
.User__userpanel {
  padding: 21px 0;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  display: flex;
  justify-content: flex-start;
}

.User_change-password {
  margin: 20px 0;
  max-width: 420px;
}
</style>
