<template>
  <div class="PasswordChange_root">
    <div class="PasswordChange_wrapper">
      <v-form ref="form" autocomplete="false">
        <TextFieldInput
          class="input-holder"
          :value="form.old_password"
          placeholder="Current password"
          type="password"
          name="password"
          label="Change password"
          bold
          :rules="[...inputRules.required]"
          :passwordInput="true"
          hide-details="auto"
          @input="updatePasswordForm(arguments[0], 'old_password')"
        />
          <TextFieldInput
            class="input-holder"
            :value="form.new_password"
            placeholder="New password"
            type="password"
            name="password"
            :rules="[...inputRules.required, ...inputRules.password]"
            :passwordInput="true"
            hide-details="auto"
            @input="updatePasswordForm(arguments[0], 'new_password')"
          />
          <TextFieldInput
            class="input-holder"
            :value="form.confirm_password"
            placeholder="Confirm password"
            type="password"
            name="password"
            :rules="[...inputRules.required, ...passwordConfirmationRule]"
            :passwordInput="true"
            hide-details="auto"
            @input="updatePasswordForm(arguments[0], 'confirm_password')"
          />
        <Button
          class="btn-large btn-bright btn-full-width btn-bright-large !text-[12px]"
          text="save new password"
          @click="handlePopup"
          brightBtn
          size="large"
        />
      </v-form>
    </div>

    <transition name="fade" mode="out-in">
      <Modal :styles="`max-width: 430px; width: 100%;`" @close="handleClose" v-if="cautionModalIsShown">
        <div slot="content">
          <img class="PasswordChangePopupImg" src="@/assets/img/_common/financeLogoBlue.svg" alt="logo" />
          <p class="PasswordChangePopupDesc">
            <span>attention!</span> For the safety of your assets withdrawals will be restricted for 24 hours after the password change
          </p>
          <div class="PasswordChangePopupButtons">
            <OutlineButton text="Save" @click="handleSumbit" />
            <Button text="cancel" size="large" @click="handleClose" />
          </div>
        </div>
      </Modal>
    </transition>
  </div>
</template>

<script>
import inputRules from '@/utils/inputRules';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Button from '@/components/_common/FormElements/Button/Button';
import { validateForm } from '@/mixins/validateForm';
import Modal from '@/components/_common/Modal/Modal';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';

export default {
  components: {
    TextFieldInput,
    Button,
    Modal,
    OutlineButton,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  mixins: [validateForm],
  data: () => ({
    inputRules,
    cautionModalIsShown: false,
  }),
  computed: {
    passwordConfirmationRule() {
      return () => this.form.new_password == this.form.confirm_password || 'Passwords do not match';
    },
  },
  methods: {
    updatePasswordForm(value, field) {
      this.$emit('updateField', {
        value,
        field,
      });
    },
    handlePopup() {
      if (this.$refs.form.validate()) {
        this.cautionModalIsShown = true;
      }
    },
    handleSumbit() {
      this.validateForm().then(() => {
        this.cautionModalIsShown = false;
        this.$emit('submitChange');
      });
    },
    handleClose() {
      this.cautionModalIsShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.input-holder {
  margin: 0 0 10px;

  &--password-change {
    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }
}

.PasswordChange_root::v-deep {
  .TextFieldInput_label label {
    @media (min-width: $desktop-width) {
      font-size: 18px;
      line-height: 120%;
    }
  }
}

.PasswordChangePopupImg {
  height: 16px;
  margin: 0 0 25px 0;
}

.PasswordChangePopupDesc {
  font-size: 12px;
  line-height: 150%;
  margin: 0;

  span {
    color: #ec5656;
    text-transform: uppercase;
  }
}
.PasswordChangePopupButtons {
  display: flex;
  margin: 30px 0 0 0;

  button {
    height: 46px;
    width: 50%;
    font-size: 12px;

    &:first-child {
      margin: 0 5px 0 0;
    }

    &:last-child {
      margin: 0 0 0 5px;
    }
  }
}
</style>